import React from 'react';
import "./about.css";

export const AboutComponent = () => {
  return (
    <div className="about-container">
      <h4>Your source for everything halal and haram! A platform for muslims to get a community sentiment on whether various topics are viewed as halal or haram.</h4>
      <br/>
    </div>
  )
}

